import { Box, Button, ButtonGroup, Icon, useColorMode } from "@chakra-ui/react"
import { FiMoon, FiSun } from "react-icons/fi"
import { useTranslation } from "next-i18next"

export function ButtonMode() {
  const { colorMode, toggleColorMode } = useColorMode()
  const { t } = useTranslation("common")
  return (
    <Box w="full" p={2}>
      <ButtonGroup w="full" isAttached variant="outline">
        <Button
          leftIcon={<Icon as={FiSun} />}
          w="full"
          isDisabled={colorMode === "light"}
          onClick={toggleColorMode}
        >
          {t("buttonMode.light")}
        </Button>
        <Button
          rightIcon={<Icon as={FiMoon} />}
          w="full"
          isDisabled={colorMode === "dark"}
          onClick={toggleColorMode}
        >
          {t("buttonMode.dark")}
        </Button>
      </ButtonGroup>
    </Box>
  )
}
