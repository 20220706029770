import { ComponentStyleConfig } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const SidebarStyles: ComponentStyleConfig = {
  parts: [
    "buttonItem",
    "iconContainer",
    "icon",
    "text",
    "sidebar",
    "logoContainer",
    "buttonItemsContainer",
    "userContainer",
    "sidebarUser",
    "nameContainer",
    "name",
    "nameSub",
    "logoutButton",
  ],
  baseStyle: (props) => ({
    buttonItem: {
      size: "sm",
      h: 10,
      w: "full",
      color: mode("blackAlpha.700", "whiteAlpha.800")(props),
      _hover: {
        bg: mode("blackAlpha.100", "whiteAlpha.100")(props),
      },
      _active: {
        borderLeftWidth: 2,
        borderLeftColor: "pink.700",
        color: "pink.300",
        bg: mode("blackAlpha.100", "whiteAlpha.100")(props),
      },
    },
    iconContainer: {
      justify: "space-between",
      align: "center",
      w: "full",
    },
    icon: {
      color: props.isActive
        ? "pink.300"
        : mode("blackAlpha.700", "whiteAlpha.800")(props),
      fontSize: "xl",
    },
    text: {
      textTransform: "initial",
      fontSize: "sm",
    },
    sidebar: {
      minW: 224,
      h: "screen",
      borderRightWidth: 1,
      borderColor: mode("blackAlpha.200", "whiteAlpha.200")(props),
      py: 4,
    },
    logoContainer: {
      w: "full",
      alignSelf: "flex-start",
      justifyContent: "space-between",
      mb: 4,
    },
    buttonItemsContainer: {
      h: "full",
      w: "full",
      spacing: 2,
      px: 2,
    },
    userContainer: {
      w: "full",
    },
    sidebarUser: {
      w: "full",
      spacing: 4,
      p: 2,
    },
    nameContainer: {
      w: "full",
    },
    name: {
      fontSize: "sm",
      fontWeight: 600,
    },
    nameSub: {
      whiteSpace: "nowrap",
      fontSize: "xs",
      textOverflow: "ellipsis",
    },
    logoutButton: {
      alignSelf: "flex-end",
      rounded: "full",
    },
    divider: {
      w: "full",
    },
  }),
}
