import { ComponentStyleConfig } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const ProjectInformationStyles: ComponentStyleConfig = {
  parts: ["projectInformationBlock", "projectInformationBlockTitle"],
  baseStyle: (props) => ({
    projectInformationBlock: {
      p: props.noPadding ? 0 : 4,
      borderBottomWidth: 1,
      borderBottomColor: props.noBorder ? "transparent" : "surfaceBorders",
      position: "relative",
    },
    projectInformationBlockTitle: {
      fontWeight: 500,
      fontSize: "xs",
      color: mode("blackAlpha.600", "whiteAlpha.500")(props),
      mb: 2,
    },
    projectInformationAttributeLabel: {
      color: mode("blackAlpha.700", "whiteAlpha.700")(props),
    },
    projectInformationAttributeRow: {
      alignItems: "center",
    },
  }),
}
