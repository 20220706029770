import { ComponentStyleConfig } from "@chakra-ui/react"

export const ProjectApplicationsStyle: ComponentStyleConfig = {
  baseStyle: () => ({
    projectApplicationIconButton: {
      rounded: "full",
    },
    projectApplicationPopover: {
      w: "180px",
      bg: "background",
      borderColor: "surfaceBorders",
    },
    projectApplicationPopoverAction: {
      justifyContent: "left",
      borderBottomWidth: 1,
      rounded: "none",
      borderBottomColor: "surfaceBorders",
    },
    projectApplicationPopoverBody: {
      p: 0,
    },
  }),
}
