/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  theme as baseTheme,
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from "@chakra-ui/react"
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools"
import { UiTheme } from "@gloually/ui"
import { StepsTheme as Steps } from "chakra-ui-steps"
import { CardStyles } from "./components/Card/styles"
import { CommentStyles } from "./components/Comment/styles"
import { CommentFormStyles } from "./components/CommentForm/styles"
import { CommentsStyles } from "./components/Comments/styles"
import { ContextualMenuStyles } from "./components/ContextualMenu/styles"
import { ContextualSidebarStyles } from "./components/ContextualSidebar/styles"
import { EntryStyles } from "./components/Entry/styles"
import { FilterCardStyles } from "./components/FilterCard/styles"
import { FiltersStyles } from "./components/Filters/styles"
import { SidebarStyles } from "./components/Layout/styles"
import { PageTitleStyles } from "./components/PageTitle/styles"
import { ProjectApplicationModalStyles } from "./components/ProjectApplicationModal/styles"
import { ProjectApplicationsStyle } from "./components/ProjectApplications/styles"
import { ProjectInformationStyles } from "./components/ProjectInformation/styles"
import { ProjectInformationAttributesStyles } from "./components/ProjectInformationAttributes/styles"
import { ProjectMembersStyles } from "./components/ProjectMembers/styles"
import { ProjectProgressStyles } from "./components/ProjectProgress/styles"
import { ProjectTicketStyles } from "./components/ProjectTicket/styles"
import { ProjectTicketAttachmentStyles } from "./components/ProjectTicketAttachment/styles"
import { ProjectTicketAttachmentsStyles } from "./components/ProjectTicketAttachments/styles"

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const styles = {
  global: (props: any) => ({
    html: {
      fontSize: "16px",
    },
    body: {
      bg: mode("white", "background")(props),
    },
  }),
}

const getButtonDefaultStyles = (props: StyleFunctionProps) => ({
  fontSize: "xs",
  bg: mode("blackAlpha.50", "whiteAlpha.100")(props),
  color: mode("black", "white")(props),

  _hover: {
    bg: mode("blackAlpha.200", "whiteAlpha.200")(props),
    _disabled: {
      bg: mode("blackAlpha.100", "whiteAlpha.100")(props),
    },
  },
  _active: {
    bg: mode("blackAlpha.200", "whiteAlpha.200")(props),
  },
})

export const components = {
  Steps,
  SidebarUser: {
    baseStyle: {
      background: "gray.900",
      alignSelf: "flex-start",
      w: "full",
      margin: 4,
      padding: 4,
    },
  },
  Card: CardStyles,
  FilterCard: FilterCardStyles,
  Filters: FiltersStyles,
  Entry: EntryStyles,
  Sidebar: SidebarStyles,
  PageTitle: PageTitleStyles,
  ProjectInformation: ProjectInformationStyles,
  ProjectProgress: ProjectProgressStyles,
  ProjectMembers: ProjectMembersStyles,
  ProjectApplicationModal: ProjectApplicationModalStyles,
  ProjectApplications: ProjectApplicationsStyle,
  ProjectInformationAttributes: ProjectInformationAttributesStyles,
  ProjectTicket: ProjectTicketStyles,
  ProjectTicketAttachment: ProjectTicketAttachmentStyles,
  ProjectTicketAttachments: ProjectTicketAttachmentsStyles,
  ContextualSidebar: ContextualSidebarStyles,
  ContextualMenu: ContextualMenuStyles,
  Comment: CommentStyles,
  Comments: CommentsStyles,
  CommentForm: CommentFormStyles,
  TicketDetail: {
    parts: ["ticketContainer"],
    baseStyle: {
      ticketContainer: {
        w: "full",
        h: "full",
        overflow: "scroll",
        pt: 8,
        pl: 8,
        pb: 24,
        pr: 48,
      },
    },
  },
  Drawer: {
    parts: ["dialog"],
    baseStyle: {
      dialog: {
        bg: "background",
      },
    },
  },
  Modal: {
    parts: ["dialog", "body", "dialogContainer", "header", "footer"],
    baseStyle: {
      dialog: {
        rounded: "md",
      },
      header: {
        bg: "background",
        borderTopLeftRadius: "md",
        borderTopRightRadius: "md",
      },
      body: {
        bg: "background",
      },
      footer: {
        bg: "background",
        borderBottomLeftRadius: "md",
        borderBottomRightRadius: "md",
      },
    },
  },
  CloseButton: {
    baseStyle: {
      borderRadius: "full",
    },
  },
  Button: {
    baseStyle: {
      textTransform: "uppercase",
    },
    variants: {
      solid: (props: StyleFunctionProps) => ({
        ...getButtonDefaultStyles(props),
        borderWidth: 1,
        borderColor: mode("blackAlpha.100", "whiteAlpha.100")(props),
      }),
      outline: (props: StyleFunctionProps) => ({
        ...getButtonDefaultStyles(props),
        border: 0,
      }),
      unstyle: () => ({
        fontSize: "xs",
      }),
      ghost: (props: StyleFunctionProps) => ({
        ...getButtonDefaultStyles(props),
        bg: "transparent",
      }),
    },
  },
  Progress: {
    defaultProps: {
      colorScheme: "primary",
    },
  },
}

const semanticTokens = {
  colors: {
    background: {
      default: "white",
      _dark: "#121212",
    },
    surface: {
      default: "blackAlpha.50",
      _dark: "whiteAlpha.50",
    },
    surfaceBorders: {
      default: "gray.200",
      _dark: "whiteAlpha.200",
    },
    surfaceText: {
      default: "blackAlpha.700",
      _dark: "whiteAlpha.700",
    },
    disabledSurfaceText: {
      default: "blackAlpha.500",
      _dark: "whiteAlpha.500",
    },
  },
}

const colors = {
  primary: baseTheme.colors.pink,
  teal: baseTheme.colors.pink,
  gray: {
    //     50: "#fafafa",
    //     100: "#f5f5f5",
    //     200: "#eeeeee",
    //     300: "#e0e0e0",
    //     400: "#bdbdbd",
    //     500: "#9e9e9e",
    //     600: "#757575",
    700: "#1F2430",
    800: "#121927",
    900: "#0D1520",
  },
}

const fonts = {
  heading: "Hevletica, sans-serif",
  body: "Hevletica, sans-serif",
}

const defaultVariants = withDefaultVariant({
  variant: "filled",
  components: ["Input", "NumberInput", "PinInput", "Select"],
})

const defaultColorSchme = withDefaultColorScheme({
  colorScheme: "teal",
  components: ["Checkbox", "Radio", "Switch", "ToggleButton"],
})

export const theme = extendTheme(defaultVariants, defaultColorSchme, {
  config,
  colors,
  semanticTokens,
  components: { ...components, ...UiTheme.components },
  styles,
  fonts,
})
