/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloProvider } from "@apollo/client"
import { ChakraProvider, ColorModeScript, GlobalStyle } from "@chakra-ui/react"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import { getSession } from "next-auth/react"
import { appWithTranslation } from "next-i18next"
import App, { AppContext } from "next/app"
import Head from "next/head"
import Script from "next/script"
import { useApollo } from "src/providers/SecureApolloProvider"
import { AuthLayout } from "../components/AuthLayout"
import Layout from "../components/Layout"
import { ProfileProvider } from "../providers/ProfileProvider"
import { SessionProvider } from "../providers/SessionProvider"
import { theme } from "../theme"

let TIME_AGO_DEFAULT_SET = false

if (!TIME_AGO_DEFAULT_SET) {
  TimeAgo.addLocale(en)
  TimeAgo.setDefaultLocale("en")
  TIME_AGO_DEFAULT_SET = true
}

function GlouallyApp({ Component, pageProps, session }: any) {
  const client = useApollo(pageProps, session?.token)
  const Wrapper =
    Component.layout === "AuthLayout"
      ? AuthLayout
      : ({ children }: any) => (
          <Layout>
            <ApolloProvider client={client}>
              <ProfileProvider>{children}</ProfileProvider>
            </ApolloProvider>
          </Layout>
        )
  return (
    <>
      <Head>
        <title>Gloually</title>
      </Head>
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <GlobalStyle />

          <Wrapper>
            <Component {...pageProps} />
          </Wrapper>
        </ChakraProvider>
      </SessionProvider>
      {process.env.NODE_ENV === "production" && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=GTM-MN9NS8C"
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-MN9NS8C');
        `}
          </Script>
        </>
      )}
    </>
  )
}

GlouallyApp.getInitialProps = async (appContext: AppContext) => {
  const session: any = await getSession(appContext.ctx)
  const appProps: any = await App.getInitialProps(appContext)

  if (
    !session &&
    !session?.user &&
    !["/login", "/signup", "/reset-password", "/new-password", "/confirm"].some(
      (url) => appContext?.ctx?.req?.url?.includes(url),
    )
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/login" })
    appContext?.ctx?.res?.end()
    return { ...appProps }
  }

  if (
    session &&
    !session?.user?.emailVerified &&
    appContext?.ctx?.req?.url !== "/confirm"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/confirm" })
    appContext?.ctx?.res?.end()
    return { ...appProps }
  } else if (
    session &&
    session?.user?.emailVerified &&
    !session?.user?.isOnboarded &&
    appContext?.ctx?.req?.url !== "/onboarding"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/onboarding" })
    appContext?.ctx?.res?.end()
    return { ...appProps }
  } else if (
    session &&
    session?.user?.emailVerified &&
    session?.user?.isOnboarded &&
    appContext?.ctx?.req?.url === "/onboarding"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/feed" })
    appContext?.ctx?.res?.end()
    return { ...appProps }
  }

  return { ...appProps, session }
}

export default appWithTranslation(GlouallyApp)
