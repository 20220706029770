import {
  Divider,
  HStack,
  Stack,
  VStack,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import {
  FiGrid,
  FiHome,
  FiPlusSquare,
  FiSettings,
  FiTrello,
  FiUsers,
} from "react-icons/fi"

import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import React from "react"
import { useSession } from "src/hooks/useSession"
import { ButtonMode } from "../ButtonMode"
import { Logo } from "../Logo"
import { SidebarButtonItem } from "./SidebarButtonItem"
import { SidebarUser } from "./SidebarUser"

const Notifications = dynamic(() => import("../Notifications"), { ssr: false })

function SidebarInner() {
  const { isOrganisation } = useSession()
  const styles = useMultiStyleConfig("Sidebar")
  const router = useRouter()

  const isActive = (path: string, equal = false) => {
    if (equal) {
      return router.pathname === path
    }
    return router.pathname.includes(path)
  }

  return (
    <VStack as="aside" sx={styles.sidebar}>
      <HStack sx={styles.logoContainer}>
        <Logo />
      </HStack>
      <Stack sx={styles.buttonItemsContainer}>
        <SidebarButtonItem
          isActive={isActive("/", true)}
          label="Dashboard"
          icon={FiHome}
          to="/"
        />
        {!isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("feed")}
            label="Feed"
            icon={FiGrid}
            to="/feed"
          />
        )}
        <SidebarButtonItem
          isActive={isActive("project")}
          label="Projects"
          icon={FiTrello}
          to="/projects"
        />
        {isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("team-members")}
            label="Team members"
            icon={FiUsers}
            to="/team-members"
          />
        )}
        {!isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("applications")}
            label="Applications"
            icon={FiPlusSquare}
            to="/applications"
          />
        )}
        <SidebarButtonItem
          isActive={isActive("settings")}
          label="Settings"
          icon={FiSettings}
          to="/settings"
        />
        <Notifications />
      </Stack>
      <Stack sx={styles.userContainer}>
        <ButtonMode />
        <Divider sx={styles.divider} />
        <SidebarUser />
      </Stack>
    </VStack>
  )
}

export const Sidebar = React.memo(SidebarInner)
