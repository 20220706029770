import { ComponentStyleConfig } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const CommentStyles: ComponentStyleConfig = {
  parts: [
    "commentContainer",
    "commentAvatar",
    "commentContent",
    "commentWrapper",
    "commentHeader",
    "commentAuthor",
    "commentTime",
    "commentTextarea",
  ],
  baseStyle: (props) => ({
    commentContainer: {
      py: 2,
      alignItems: "flex-start",
    },
    commentAvatar: {
      mr: 4,
    },
    commentContent: {
      w: "full",
    },
    commentWrapper: {
      bg: "surface",
      w: "full",
      p: 4,
      px: 6,
      rounded: "md",
      mb: 1,
    },
    commentHeader: {
      justifyContent: "space-between",
    },
    commentAuthor: {
      fontSize: "sm",
      fontWeight: 700,
      mr: 2,
    },
    commentTime: {
      fontSize: "sm",
      color: mode("blackAlpha.600", "whiteAlpha.600")(props),
    },
    commentTextarea: {
      borderColor: "transparent",
      p: 0,
      outline: "none",
      bg: "transparent",
    },
  }),
}
