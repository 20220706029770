import {
  Avatar,
  Box,
  HStack,
  Link,
  Icon,
  IconButton,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import { signOut } from "next-auth/react"
import { useSession } from "../../hooks/useSession"
import { FiLogOut } from "react-icons/fi"

export function SidebarUser() {
  const styles = useMultiStyleConfig("Sidebar")
  const { session } = useSession()

  return (
    <HStack sx={styles.sidebarUser}>
      <Avatar size="sm" sx={styles.avatar} src={String(session?.user?.image)} />
      <Box sx={styles.nameContainer}>
        {!session?.user?.organisation ? (
          <Link href="/profile" sx={styles.name}>
            {session?.user?.name}
          </Link>
        ) : session?.user?.organisation ? (
          <Link
            href={`/organisation/${session?.user?.organisation.id}`}
            sx={styles.name}
          >
            {session?.user?.name}
          </Link>
        ) : null}

        <Text sx={styles.nameSub}>
          {session?.user?.organisation && session?.user?.organisation.name}
          {!session?.user?.organisation && session?.user?.email}
        </Text>
      </Box>
      <IconButton
        sx={styles.logoutButton}
        size="sm"
        variant="ghost"
        aria-label="Logout"
        icon={<Icon as={FiLogOut} />}
        onClick={() => signOut({ redirect: true, callbackUrl: "/login" })}
      />
    </HStack>
  )
}
