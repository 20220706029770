import { ComponentStyleConfig } from "@chakra-ui/react"

export const FiltersStyles: ComponentStyleConfig = {
  parts: ["heading", "icon"],
  baseStyle: {
    heading: {
      fontSize: "lg",
      p: 4,
      pt: 0,
      display: "flex",
    },
    icon: {
      position: "relative",
      mr: 2,
    },
  },
}
