import { ComponentStyleConfig } from "@chakra-ui/react"

export const ContextualMenuStyles: ComponentStyleConfig = {
  parts: [
    "contextualMenuIconButton",
    "contextualMenuPopover",
    "contextualMenuPopoverAction",
    "contextualMenuPopoverBody",
  ],
  baseStyle: () => ({
    contextualMenuIconButton: {
      rounded: "full",
    },
    contextualMenuPopover: {
      w: "180px",
      bg: "background",
      borderColor: "surfaceBorders",
    },
    contextualMenuPopoverAction: {
      justifyContent: "left",
      borderBottomWidth: 1,
      rounded: "none",
      borderBottomColor: "surfaceBorders",
    },
    contextualMenuPopoverBody: {
      p: 0,
    },
  }),
}
