import { ComponentStyleConfig } from "@chakra-ui/react"

export const ProjectMembersStyles: ComponentStyleConfig = {
  parts: [
    "projectMembersBlock",
    "projectMembersBlockTitle",
    "projectMemberName",
  ],
  baseStyle: (props) => ({
    projectMembersBlock: {
      gap: 1,
    },
    projectMember: {
      w: "full",
      opacity: props.isDeclined ? 0.5 : 1,
    },
    projectMemberNameContainer: {
      w: "full",
    },
    projectMemberName: {
      fontSize: "sm",
      fontWeight: "500",
      textDecoration: props.isDeclined ? "line-through" : undefined,
    },
    projectMemberNameSub: {
      lineHeight: 0.8,
      fontSize: "xs",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  }),
}
