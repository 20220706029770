import { LinkBox } from "@chakra-ui/react"
import { Icon } from "./Icon"

export function Logo() {
  return (
    <LinkBox as="a" href="/" px={4}>
      <Icon />
    </LinkBox>
  )
}
