import { ComponentStyleConfig } from "@chakra-ui/react"

export const ProjectTicketAttachmentStyles: ComponentStyleConfig = {
  baseStyle: () => ({
    projectTicketAttachmentContainer: {
      justifyContent: "space-between",
      w: "full",
      py: 2,
    },
    projectTicketAttachmentText: {
      fontSize: "xs",
      maxW: "220px",
    },
  }),
}
